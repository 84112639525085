import { FC, Suspense, useContext, useEffect, useState } from 'react'
import { FCMSupport, requestForToken } from '@olagg/plugins'
import { Mumbai, Polygon } from "@thirdweb-dev/chains";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

// Thirdweb v4
import {
  ThirdwebProvider,
  en,
  es,
  metamaskWallet,
  walletConnect
} from '@thirdweb-dev/react'

// Thirdweb v5
import { ThirdwebProvider as ThirdwebProviderV5 } from "thirdweb/react";

import { analytics } from './analytics'
import { getMessaging, onMessage } from 'firebase/messaging'
import { useAuthStore, useOnboardingStore } from '@olagg/store'
import { useToast } from '@chakra-ui/react'

import Authenticate from './modules/Auth/Authenticate'
import Layout from './components/Layout'
import Loading from './components/Loading';
//PROMOS
import Login from '@olagg/ui-kit/Login'
import Maintenance from '@olagg/ui-kit/Maintenance'
import { OSName } from './utils/operationSystem'
import PartnersLanding from './modules/Quest/components/PartnersLanding';
import Quest from './modules/Quest'
import { ROUTES } from './routes'
import { STEPS } from './modules/Auth/constants'
import useAuthProviders from './modules/Auth/hooks/useAuthProviders'
import useWeb3 from './hooks/useWeb3'
import { AdTrackingContext } from './contexts/adTrackingContext'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

type NotificationData = {
  title: string,
  body: string,
  image?: string,
  icon?: string,
  click_action?: string,
}

type Payload = {
  notification: NotificationData
  data: NotificationData
}

interface Window {
  prerenderReady: boolean;
}

const App = () => {
  useEffect(() => {
    const storedLang = localStorage.getItem('i18n_lang');
    if (!storedLang) {
      const browserLang = getBrowserLanguage();
      localStorage.setItem('i18n_lang', browserLang);
    }
  }, []);

  const { me, loggedIn, getMe } = useAuthStore()

  return (
    <Routes>
      <Route path={'/en'} element={<Navigate to='/en/olagg' />} />
      <Route path={'/es'} element={<Navigate to='/es/olagg' />} />
      <Route path={'/pt'} element={<Navigate to='/pt/olagg' />} />
      <Route path={`${ROUTES.HOME}`} element={<Navigate to='/olagg' />} />
      <Route path={`${ROUTES.HOME}/:partner`} element={<DynamicRedirect />} />
      <Route path={`${ROUTES.LOGIN}`} element={<DynamicRedirect />} />
      <Route path="/:lang/*" element={<LanguageHandler loggedIn={loggedIn} me={me} />} />
    </Routes>
  );
};

const getBrowserLanguage = () => {
  const lang = navigator.language.split('-')[0];
  return ['en', 'es', 'pt'].includes(lang) ? lang : 'en';
};

const DynamicRedirect = () => {
  const location = useLocation();
  const storedLang = localStorage.getItem('i18n_lang') || getBrowserLanguage();
  const newPath = `/${storedLang}${location.pathname}${location.search}`;
  return <Navigate to={newPath} replace />;
};

const LanguageHandler = ({ loggedIn, me }) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { getMe } = useAuthStore()
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const { pathname, state } = useLocation()
  const [searchParams] = useSearchParams()
  const pathnameExtract = (pathname.split(`/`)[1] == 'login') || pathname.split(`/`)[2] == 'login' ? searchParams.get('partner') : pathname.split(`/`)[2]

  const navigate = useNavigate()
  const [notification, setNotification] = useState({ title: '', body: '' });
  const toast = useToast()
  const { checkAuthStatusSupabase, initRedirectPath, checkAuthStatusNewsroom } = useAuthProviders()
  const { web3Status } = useWeb3()
  const isWeb3Enabled = web3Status()

  const notify = (notification: NotificationData) => toast({
    title: notification.title,
    status: 'success',
    description: notification.body,
    position: 'top-right',
    duration: 5000,
    isClosable: true
  });

  useEffect(() => {
    checkAuthStatusSupabase()
    checkAuthStatusNewsroom()
    initRedirectPath()
  }, [])


  useEffect(() => {
    if (notification?.title) {
      notify(notification)
    }
  }, [notification])


  if (FCMSupport) {
    requestForToken(me?.id);

    onMessage(getMessaging(), (payload) => {
      console.log("Message received. ", payload)
      const data = payload.data
      setNotification({ title: data?.title, body: data?.body });
    })
  }

  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId')

  if (searchParams.get('referrerId'))
    localStorage.setItem('referrerId', referrerId)

  // const redirect = searchParams.get('redirect')

  const utm_path =
    searchParams.get('utm_path') ||
    localStorage.getItem('utm_path') ||
    ''

  if (searchParams.get('utm_path'))
    localStorage.setItem('utm_path', utm_path)

  useEffect(() => {
    if (
      pathname.startsWith('/becas') ||
      pathname.startsWith('/mi-perfil')
    ) {
      document.body.style.backgroundColor = `#FFFFFF`
    } else if (
      pathname.startsWith('/gamergy')
    ) {
      document.body.style.backgroundColor = `#3812AF`
    } else {
      document.body.style.backgroundColor = `#0C0324`
    }
    getMe()
  }, [, pathname])

  useEffect(() => {
    const c = setTimeout(() => {
      if (me) {
        localStorage.removeItem('utm_path')

        if (utm_path) {
          navigate(utm_path)
        }
      }
    }, 5)

    return () => clearTimeout(c)
  }, [me])

  const olaChain = (import.meta.env.VITE_OLAGG_CHAIN === 'Mumbai' ? Mumbai : Polygon);
  const {
    openOnboarding,
    isOnboardingOpen,
    isRedirected,
    isSignedIn,
    setIsRedirected,
    setIsSignedIn,
    providerSignedIn,
    setForceDiscord,
    setStep,
    step
  } = useOnboardingStore()

  useEffect(() => {
    if (isOnboardingOpen == true) {
      triggerLoginViewedEvent()
    }
  }, [isOnboardingOpen])

  useEffect(() => {
    if (isRedirected == true) {
      triggerRedirectEvent()
    }
  }, [isRedirected])

  useEffect(() => {
    if (isSignedIn == true) {
      triggerSignedInEvent()
    }
  }, [isSignedIn])

  setTimeout(() => {
    (window as unknown as Window).prerenderReady = true
  }, 10000)

  const triggerRedirectEvent = () => {
    let redirectData = searchParams.get('redirect')
    let props = {
      url: redirectData,
      user_agent: navigator.userAgent,
      owner: pathnameExtract
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()?.track("User Redirected", props)
    setIsRedirected(false)
  }

  const checkForceDiscord = () => {
    if (step !== STEPS.none) return;
    const requireDiscordAuth = searchParams.get('require-discord-auth') || localStorage.getItem("require-discord-auth")

    if (requireDiscordAuth) {
      setForceDiscord(true)
      if (me && !me?.authProviderData?.discord?.id && step == STEPS.none) {
        setTimeout(() => {
          openOnboarding(true)
          setStep(STEPS.linkDiscord)
        }, 1000)
      } else {
        openOnboarding(false)
      }
    }
  }

  useEffect(() => {
    checkForceDiscord()
  }, [step])


  useEffect(() => {
    const redirect = searchParams.get('redirect')
    if (redirect) {
      localStorage.setItem('redirect', redirect)
    }
  }, [me])

  const checkInApp = () => {
    if (navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV') || navigator.userAgent.includes('Instagram')) {
      return true
    } else {
      return false
    }
  }

  const triggerLoginViewedEvent = () => {
    let redirectData = searchParams.get('redirect')
    let props = {
      redirect: redirectData,
      is_webview: checkInApp(),
      url: window.location.href,
      user_agent: navigator.userAgent,
      owner: pathnameExtract
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()?.track("Login Viewed", props)
  }

  const triggerSignedInEvent = () => {
    let props = {
      identity_provider: providerSignedIn,
      user_agent: navigator.userAgent,
      owner: pathnameExtract
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('User SignedIn', props)

    analytics()
      ?.identify(`${me?.id}`, {
        email: me?.email,
        name: me?.name,
        google_id: me?.authProviderData.hasOwnProperty('google') ? me?.authProviderData.google.id : '',
        discord_id: me?.authProviderData.hasOwnProperty('discord') ? me?.authProviderData.discord.id : '',
        game_categories: me?.gameCategories,
        opportunities: me?.opportunities,
        level: me?.level?.value,
        xp: me?.level.score,
        identity_provider: providerSignedIn,
        user_agent: navigator.userAgent,
        owner: pathnameExtract,
        human_verification_level: me?.verification?.level,
        human_verification_providers: me?.verification?.providers,
      })
    setIsSignedIn(false, '')
  }

  const handleNavigationIfLoggedIn = () => {
    triggerRedirectEvent()

    const urlFull = (window.location.href).split('&')
    let queryParams = ''
    if (urlFull.length > 1) {
      urlFull.map((p, i) => {
        if (i > 0) {
          queryParams = (i == 1) ? `?${p}` : `${queryParams}&${p}`
        }
      })
    }

    let redirectData = `${searchParams.get('redirect') || localStorage.getItem("redirect")}${queryParams}`

    localStorage.removeItem("redirect")

    const requireDiscordAuth = searchParams.get('require-discord-auth')
    if (requireDiscordAuth && !redirectData.includes('require-discord-auth')) {
      redirectData = `${redirectData}?require-discord-auth=${requireDiscordAuth}`
    }
    if (redirectData.startsWith('https://')) {
      navigate('/redirect', { state: { url: redirectData } })
    } else {
      navigate(redirectData)
    }
  }

  const supportedWallets = () => {
    if (OSName() == 'macOS' || OSName() == 'Windows' || OSName() == 'Linux') {
      return [
        metamaskWallet(),
        walletConnect({
          projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
        }),
      ]
    } else {
      return [
        walletConnect({
          projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
        })
      ]
    }
  }

  const browserLanguage: string = navigator.language.slice(0, 2);
  const locale = browserLanguage === 'es' ? es() : en();

  //HANDLE LANGUAGE
  useEffect(() => {
    const storedLang = localStorage.getItem('i18n_lang');
    const browserLang = getBrowserLanguage();

    if (lang && (lang !== storedLang && lang !== browserLang)) {
      localStorage.setItem('i18n_lang', lang);
    }

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const currentPatchname = `${location.pathname}`

  return (
    <Suspense fallback={<Loading />}>
      {import.meta.env.VITE_MAINTENANCE === 'TRUE' ? (
        <Maintenance />
      ) : (
        <ThirdwebProvider
          activeChain={olaChain}
          supportedChains={[olaChain]}
          supportedWallets={supportedWallets()}
          clientId={import.meta.env.VITE_THIRDWEB_CLIENT_ID}
          locale={locale}
        >
          <ThirdwebProviderV5>
            <Helmet>
              <link rel="canonical" href={`${import.meta.env.VITE_BASE_URL}/${i18n.language}/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="es" href={`${import.meta.env.VITE_BASE_URL}/es/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="en" href={`${import.meta.env.VITE_BASE_URL}/en/${currentPatchname.split('/').slice(2).join('/')}`} />
              <link rel="alternate" hrefLang="pt" href={`${import.meta.env.VITE_BASE_URL}/pt/${currentPatchname.split('/').slice(2).join('/')}`} />
            </Helmet>
            <Routes>
              <Route
                path={ROUTES.LOGIN}
                element={
                  !loggedIn ? (
                    <>
                      <Login
                        onClickLogin={provider => {
                          localStorage.setItem(
                            'provider',
                            provider
                          )
                        }}
                        onLoad={() => {
                          console.log('Redirecting to /login');
                        }}
                        referrerId={referrerId}
                        redirect={utm_path}
                      />
                      <Authenticate />
                    </>
                  ) : (
                    (searchParams.get('redirect') || localStorage.getItem("redirect")) ? handleNavigationIfLoggedIn() : <Navigate to={'/'} />
                  )
                }
              />
              <Route path="/" element={<Layout />}>
                <Route path={`${ROUTES.HOME}/:partner`} element={
                  !loggedIn ? (
                    <PartnersLanding />
                  ) : (
                    <Quest />
                  )
                } />
              </Route>
            </Routes>

          </ThirdwebProviderV5>
        </ThirdwebProvider>
      )}
    </Suspense>
  )
}

export default App
